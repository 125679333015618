<template>
    <v-card flat>
        <v-card-text class="pl-0 pr-0 pb-0 mt-n4">
            <v-simple-table class="border-table">
                <template v-slot:default>
                    <thead>
                    <tr style="background-color:#F4F6F8; height:58px; color:#333333;">
                        <th class="text-center text-h6">이름</th>
                        <th class="text-center text-h6">휴대폰 번호</th>
                        <th class="text-center text-h6">앱보유현황</th>
                        <th class="text-center text-h6">비고</th>
                        <th class="text-center text-h6">삭제</th>
                        <th class="text-center text-h6">순서</th>
                    </tr>
                    </thead>
                    <draggable v-model="staffList" tag="tbody" @start="onDragStart" @end="onDragEnd" handle=".handle" style="font-size:18px; color:#333333;">
                        <template v-for="(staffItem, staffIdx) in staffList">
                            <tr style="height:60px;" :key="'staff_' + staffIdx" :class="{ 'handle': true, 'selectedTr': (staffIdx == selectedIdx) }">
                                <td class="text-center text-subtitle-1" @click="openModifyPopup(staffItem, staffIdx)" style="cursor: pointer">
                                    <span :class="{'text-decoration-line-through' : (staffItem.edit_status == 'DELETE')}">{{ staffItem.user_name }}</span>
                                    <v-chip small color="red" outlined class="ml-3" v-if="staffItem.edit_status == 'ADD'">추가</v-chip>
                                    <v-chip small color="red" outlined class="ml-3" v-if="staffItem.edit_status == 'DELETE'">삭제</v-chip>
                                    <v-chip small color="green" outlined class="ml-3" v-if="staffItem.edit_status == 'MODIFY'">변경</v-chip>
                                    <v-chip small color="blue" outlined class="ml-3" v-if="staffItem.admin_yn == 'Y'">관리자</v-chip>
                                </td>
                                <td class="text-center text-subtitle-1">
                                    <span v-if="staffItem.device_type == 'LANDLINE' && !staffItem.user_mdn">
                                        <v-chip small color="blue" outlined class="ml-3">미확정</v-chip>
                                    </span>
                                    <span v-else>{{ staffItem.user_mdn | phoneNum }}</span>
                                </td>
                                <td class="text-center text-subtitle-1">
                                    <span v-if="staffItem.device_type == 'LANDLINE'">유선단말</span>
                                    <span v-else-if="staffItem.app_reg_yn == 'Y'">보유</span>
                                    <span v-else style="color:red;">미보유</span>
                                </td>
                                <td class="text-left text-subtitle-1" @click="openModifyPopup(staffItem, staffIdx)">
                                    <span :class="{'text-decoration-line-through' : (staffItem.edit_status == 'DELETE')}">{{ staffItem.mng_memo }}</span>
                                </td>
                                <td class="text-center text-subtitle-1">
                                    <v-btn icon @click="delUser(staffIdx)" v-if="staffItem.admin_yn != 'Y' && staffItem.edit_status != 'DELETE'" title="삭제">
                                        <v-icon>mdi-delete-outline</v-icon>
                                    </v-btn>
                                    <v-btn icon @click="restoreUser(staffIdx)" v-if="staffItem.edit_status == 'DELETE'" title="삭제취소">
                                        <v-icon>mdi-undo</v-icon>
                                    </v-btn>
                                </td>
                                <td class="text-center text-subtitle-1">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon v-on="on">
                                                <v-icon>mdi-drag-horizontal-variant</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>드래그하여 순서를 변경할 수 있습니다</span>
                                    </v-tooltip>
                                </td>
                            </tr>
                        </template>
                    </draggable>
                </template>
            </v-simple-table>
        </v-card-text>
        <v-row>
            <v-col>
                <v-card-text class="text-center">
                    <v-btn outlined large rounded class="text-h6" color="primary" @click="openAddPopup">
                        <v-icon left dark size="25">mdi-plus-circle</v-icon>
                        콜리 추가
                    </v-btn>
                </v-card-text>
            </v-col>
        </v-row>
        <v-alert
            class="mt-10 align-center"
            dense
            outlined
            type="error"
            prominent
        >
            <v-icon size="1" color="red">mdi-moon-full</v-icon> 콜리 추가 시 <span class="text-decoration-underline font-weight-bold">인당 요금이 추가</span>됩니다. <br />
            <v-icon size="1" color="red">mdi-moon-full</v-icon> 첫 달 이용요금은 말일까지 <span class="text-decoration-underline font-weight-bold">이용일수만큼 일할 계산</span>하여 다음달에 청구됩니다. <br />
            <v-icon size="1" color="red">mdi-moon-full</v-icon> 콜리를 추가/삭제한 경우 추가/삭제한 <span class="text-decoration-underline font-weight-bold">당일을 포함해 이용일만큼 과금</span>됩니다. <br />
        </v-alert>

        <v-dialog v-model="modal" persistent max-width="600" @keydown.esc="closePopup()">
            <v-flex v-if="dialogMode === 'add'">
                <popup-add v-on:submit="submitAdd" v-on:cancel="closePopup"></popup-add>
            </v-flex>
            <v-flex v-if="dialogMode === 'modify'">
                <popup-modify v-on:submit="submitModify" v-on:cancel="closePopup" :item-idx="modifyIdx"></popup-modify>
            </v-flex>
        </v-dialog>
    </v-card>
</template>
<script>
import draggable from 'vuedraggable'

export default {
    name: 'scn_staff_list',
    components: {
        'PopupAdd': () => import('@/components/svc/staff/popup_add.vue'),
        'PopupModify': () => import('@/components/svc/staff/popup_modify.vue'),
        draggable
    },
    data: () => ({
        modal: false,
        dialogMode: 'none',
        modifyIdx: -1,
        selectedIdx: -1,
    }),
    computed: {
        // ...mapGetters({
        //     staffList: 'svcStaff/staffList',
        // }),
        // ...mapState('svcStaff', {
        //     staffList: state => state.staffList,
        // }),   
        staffList: {
            get() {
                return this.$store.state.svcStaff.staffList
            },
            set(v) {
                this.$store.commit('svcStaff/setStaffList', v)
            }
        }
    },
    created() {
        this.doSearch()
    },
    mounted() {
        this.$emit("page-loaded")
    },
    methods: {
        openAddPopup() {
            this.dialogMode = 'add'
            this.modal = true
        },
        openModifyPopup(item, idx) {
            if (item.edit_status == 'DELETE') {
                return
            }

            this.modifyIdx = idx
            this.dialogMode = 'modify'
            this.modal = true
        },
        closePopup() {
            this.modal = false
            this.dialogMode = 'none'
        },
        submitAdd() {
            this.closePopup()
        },
        submitModify() {
            this.closePopup()
        },
        delUser(idx) {
            let confirmMsg = '콜리 정보를 삭제 하시겠습니까?'
            this.$store.dispatch('openConfirm', {
                message: confirmMsg,
                sub_message: '삭제시 해당 콜리 앱접속이 불가하며, 등록된 ARS시나리오에서 삭제됩니다.',
                va: this,
                okCb: function (va) {
                    va.$store.commit('svcStaff/delStaff', idx)
                    va.$store.dispatch('openAlert', {
                        message: '삭제되었습니다',
                        sub_message: '꼭! 상단의 저장버튼을 눌러야 변경사항이 저장됩니다'
                    })
                }
            })
        },
        restoreUser(idx) {
            this.$store.dispatch('openConfirm', {
                message: '콜리 삭제를 취소 하시겠습니까?',
                va: this,
                okCb: function (va) {
                    va.$store.commit('svcStaff/restoreStaff', idx)
                    va.$store.dispatch('openAlert', {
                        message: '삭제 취소 되었습니다',
                        sub_message: '꼭! 상단의 저장버튼을 눌러야 변경사항이 저장됩니다'
                    })
                }
            })
        },
        doSearch() {
            this.$store.dispatch('svcStaff/getList', {
                scb: c => {
                }, fcb: err => {
                    if (this.$noSessionProc(err)) {

                    }
                }
            })
        },
        onDragStart(p) {
            this.selectedIdx = p.oldIndex
        },
        onDragEnd(p) {
            /*
            this.$store.commit('svcVoiceScn/resetRingUserOrder', {})            
            */
            let ctx = this
            setTimeout(() => {
                ctx.selectedIdx = -1
            }, 1000)
        }
    }
}
</script>